import React from 'react'
import styled from 'styled-components'
import Card from './Card'

const EventCard = ({
  slug,
  name,
  location,
  numberOfPeople,
  logo,
  pictures,
}) => {
  return (
    <Card
      label={name}
      sublabel={location}
      info={numberOfPeople + ' pers.'}
      picture={pictures[0]}
      link={slug}
      logo={logo}
      cta="Voir"
      event={true}
      shapeStyle={{
        width: '16px',
        height: '16px',
        border: '5px solid #BC8046',
        borderRadius: '50%',
        marginBottom: '22px',
        position: 'relative',
        transform: 'unset',
      }}
    />
  )
}

const StyledEventCard = styled.figure``

export default EventCard
export { StyledEventCard }
