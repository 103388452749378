import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors, homeLayout } from '../theme'
import ParallaxShape from '../components/ParallaxShape'
import Card from '../components/Card'
import { track } from '../analytics'

function getPrice(pricePerPerson) {
  return pricePerPerson.toFixed(2) + '€<br /><p>HT par pers.</p>'
}

const MenusGrid = ({ menus, category, pageContext }) => {
  return (
    <StyledMenusGrid className="menus-container">
      <ParallaxShape
        x={85}
        y={-2}
        shapeStyle={{
          width: '200px',
          height: '400px',
          border: '140px solid ' + colors.brand.beige,
          borderRight: 'none',
          borderBottomLeftRadius: '800px',
          borderTopLeftRadius: '800px',
          transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
          opacity: '0.5',
        }}
      />
      <ParallaxShape
        x={-5}
        y={5}
        shapeStyle={{
          width: '400px',
          height: '400px',
          border: '140px solid ' + colors.brand.beige,
          borderRadius: '50%',
          opacity: '0.5',
        }}
      />
      <ParallaxShape
        x={80}
        y={32}
        shapeStyle={{
          width: '400px',
          height: '200px',
          border: '140px solid ' + colors.brand.beige,
          borderBottom: 'none',
          borderTopLeftRadius: '400px',
          borderTopRightRadius: '400px',
          transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
          opacity: '0.5',
        }}
      />
      <div className="menus">
        {menus.map(({ node: menu }, index) => (
          <Card
            key={index}
            label={menu.menuName}
            subLabel={menu.menuDescription}
            picture={
              menu.mainPhotoTransformed
                ? menu.mainPhotoTransformed.childImageSharp
                : pageContext.defaultCategoryPic
            }
            link={'/menus/' + menu.fileName}
            cta="Commander"
            chip={getPrice(menu.sellingPricePerPerson)}
            menu={true} // TODO : changer les images en fixed
            onClick={() =>
              track('OpenMenu', {
                label: `MenuId_${menu.menuId}`,
                category: category,
              })
            }
            shapeStyle={{
              width: '16px',
              height: '20px',
              backgroundColor: '#BC8046',
              clipPath: 'polygon(0% 0%, 25% 0%, 100% 100%, 75% 100%)',
              marginBottom: '22px',
              position: 'relative',
              transform: 'unset',
            }}
          />
        ))}
      </div>
    </StyledMenusGrid>
  )
}

const StyledMenusGrid = styled.div`
  position: relative;

  .menus {
    position: relative;
    width: ${homeLayout.width};
    margin: auto;
    padding-top: 100px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 15px;
    grid-row-gap: 40px;
    margin-bottom: 40px;

    .sublabel {
      max-width: 250px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .chip {
      flex-direction: column;

      p {
        font-family: Centra;
        margin-top: 0;
        font-size: 9px;
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .menus {
      grid-template-columns: repeat(2, auto);
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .menus {
      padding-top: 30px;
      display: flex;
      flex-direction: column;

      > :not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
`

export default MenusGrid
export { StyledMenusGrid }
