import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../theme'
import ParallaxShape, { StyledParallaxShape } from '../components/ParallaxShape'
import GoogleRating from '../components/GoogleRating'
import Link from '../components/Link'
import { Button } from '@monbanquet/crumble'

const GoogleRatingContainer = () => (
  <StyledGoogleRatingContainer>
    <ParallaxShape
      x={75}
      y={10}
      shapeStyle={{
        width: '437px',
        height: '437px',
        borderRadius: '50%',
        border: '145px solid ' + colors.brand.beige,
        opacity: '0.5',
      }}
    />
    <GoogleRating />
    <Link
      to={`https://www.google.com/search?q=monbanquet&oq=monbanquet
                         &aqs=chrome..69i57j69i60l3j69i59.1416j0j7&sourceid=chrome&ie=
                         UTF-8#lrd=0x47e66e76a78ab02d:0xdc91ff2723f1b0f1,1,,,`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button className="google-button">Voir les avis Google</Button>
    </Link>
  </StyledGoogleRatingContainer>
)

const StyledGoogleRatingContainer = styled.div`
  position: relative;
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 85px;

  a {
    z-index: 1;
  }
  .google-button {
    margin-top: 32px;
    background-color: ${colors.secondary.yellow};
    color: ${colors.brand.black};
    height: 66px;
    font-size: 19px;
    text-transform: none;
    padding: 22px 64px;
  }
  @media (max-width: ${breakpoints.desktop}px) {
    ${StyledParallaxShape} {
      display: none;
    }
    margin-top: 45px;
    margin-bottom: 70px;

    .google-button {
      height: 46px;
      font-size: 14px;
      padding: 15px 46px;
    }
  }
`
export default GoogleRatingContainer
export { StyledGoogleRatingContainer }
