import { EventContext } from './context/EventContext'
import React, { useContext } from 'react'
import { Dropdown } from '@monbanquet/crumble'

const toItems = items => items.map(i => ({ label: `${i} pers.`, value: i }))

const NbGuestDropdown = ({
  guestsItems,
  onChangeGuests = () => {},
  ...props
}) => {
  const {
    state: { nbGuests },
    dispatch,
  } = useContext(EventContext)
  return (
    <Dropdown
      items={toItems(guestsItems)}
      value={nbGuests}
      onChange={nbg => {
        dispatch({
          type: 'UPDATE_GUESTS',
          payload: { nbGuests: parseInt(nbg) },
          analytics: { label: 'From_NbGuestsDropdown' },
        })
        onChangeGuests(parseInt(nbg))
      }}
      {...props}
    />
  )
}

export default NbGuestDropdown
