import { EventContext } from './context/EventContext'
import React, { useContext } from 'react'
import { Dropdown } from '@monbanquet/crumble'
import { useRouteLoading } from '../hooks/useRouteLoading'
import { defaultCities } from './context/EventContextDefaults'

const CategoriesDropdown = ({ onChangeZip = () => {}, ...props }) => {
  const {
    state: { zipcode },
    dispatch,
  } = useContext(EventContext)

  const { loading, shouldLoad } = useRouteLoading(
    'routeUpdateDelayed',
    'routeUpdate',
    { initialShouldLoad: false },
  )

  return (
    <Dropdown
      items={defaultCities}
      value={parseInt(`${zipcode.toString().substring(0, 2)}000`)} // only take the first two digits into account: 75000 = paris, 69000=lyon}
      loading={loading}
      onChange={zip => {
        shouldLoad.current = true
        dispatch({
          type: 'UPDATE_ZIP',
          payload: { zipcode: zip },
          analytics: { label: 'From_CitiesDropdown' },
        })
        onChangeZip(zip)
      }}
      {...props}
    />
  )
}

export default CategoriesDropdown
