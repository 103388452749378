import { useRef, useState } from 'react'
import { useEventListener } from '@monbanquet/crumble'

/**
 *
 * @param {*} delayedEventName the event name that triggers the loading state
 * @param {*} doneEventName the event name that stops the loading state
 * @param {*} options.initialShouldLoad initialShouldLoad is true by default, which means that
 * all elements that use useRouteLoading will get triggered. If you want only one element to trigger,
 * set options.intitialShould to false. then toggle it on a button click (for example) to allow it to listen for
 * route loading events. See CategoriesDropdown for an example.
 * @returns {loading, shouldLoad: {current}}.
 * `loading` is the state of the route loading.
 * `shouldLoad.current` is a react ref. that you can mutate on a button click to allow the loading state to trigger.
 */

export function useRouteLoading(
  delayedEventName,
  doneEventName,
  { initialShouldLoad = true } = {},
) {
  const shouldLoad = useRef(initialShouldLoad)
  const [loading, setLoading] = useState(false)
  useEventListener(
    delayedEventName,
    () => shouldLoad.current && setLoading(true),
  )
  useEventListener(doneEventName, () => {
    setLoading(false)
    shouldLoad.current = false
  })
  return { loading, shouldLoad }
}
