// Handles URLs like "domain.com" and "//domain.com"
export default function prependProtocol(url) {
  if (!url || !url.length || /^https?:\/\//i.test(url)) {
    return url
  }

  let fixedUrl = url
  if (/\/\//i.test(fixedUrl)) {
    fixedUrl = 'https:' + fixedUrl
  } else {
    fixedUrl = 'https://' + fixedUrl
  }
  return fixedUrl
}
