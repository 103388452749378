import { EventContext } from './context/EventContext'
import React, { useContext } from 'react'
import { Dropdown } from '@monbanquet/crumble'
import { useRouteLoading } from '../hooks/useRouteLoading'
import { filter } from 'lodash'

const CategoriesDropdown = ({ onChangeCategory = () => {}, ...props }) => {
  const {
    state: { category },
    allDropDownCategoriesFiltred,
    dispatch,
  } = useContext(EventContext)

  const { loading, shouldLoad } = useRouteLoading(
    'routeUpdateDelayed',
    'routeUpdate',
    { initialShouldLoad: false },
  )
  const filteredCategories = filter(allDropDownCategoriesFiltred, category => {
    return !category.hideMenus
  })

  const defaultCategories = [
    { order: 9, slug: `noel` },
    { order: 10, slug: `galette-des-rois` },
    { order: 1, slug: `petit-dejeuner` },
    { order: 3, slug: `cocktail-dejeunatoire` },
    { order: 2, slug: `pause-gourmande` },
    { order: 5, slug: `cocktail-aperitif` },
    { order: 6, slug: `cocktail-dinatoire` },
    { order: 7, slug: `journee-complete` },
    { order: 11, slug: `planches-a-partager` },
    { order: 12, slug: `animation` },
    { order: 13, slug: `dejeuner-buffet` },
    { order: 4, slug: `plateaux-repas` },
    { order: 8, slug: `evenement-du-mois` },
    { order: 14, slug: `noel-paris` },
    { order: 15, slug: `noel-lyon` },
  ]
  const categoryOrderMap = new Map()
  defaultCategories.forEach(category => {
    categoryOrderMap.set(category.slug, category.order)
  })

  filteredCategories.sort((a, b) => {
    const orderA = categoryOrderMap.get(a.slug) || Infinity // Use Infinity for unmatched labels
    const orderB = categoryOrderMap.get(b.slug) || Infinity
    return orderA - orderB
  })

  return (
    <Dropdown
      items={filteredCategories}
      value={category}
      loading={loading}
      onChange={cat => {
        shouldLoad.current = true
        dispatch({
          type: 'UPDATE_CATEGORY',
          payload: { category: cat },
          analytics: { label: 'From_CategoriesDropdown' },
        })
        onChangeCategory(cat)
      }}
      {...props}
    />
  )
}

export default CategoriesDropdown
